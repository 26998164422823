import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FirmModel } from '../model/firm/firm.model';
import { AclService } from './acl.service';
import { AuthService } from './auth.service';
import { FirmService } from './firm.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private allowedPagesOnBadBilling = [
    '/dashboard/members/settings/billing',
    '/login',
    '/firm',
    '/dashboard/members/settings/billing?check=payment',
    '/dashboard/members/settings/billing?check=cancel',
    '/dashboard/members/settings/billing?check=card',
    '/dashboard/members/settings/billing/delete',
    '/dashboard/help/video-tutorials',
    '/dashboard/help/support',
    '/dashboard/members/settings/billing/add-new-card',
    '/dashboard/members/settings/billing/stepper',
    '/dashboard/members/settings/billing/add-remove'
  ];

  private allowedPagesOnRoleIdTwo: string[] = [
    '/dashboard/members/settings/teamaccess',
    '/dashboard/members/settings/time-tracking-setting'
  ];
  private allowedPagesWithoutToken: string[] = []
  private firm: FirmModel;
  private isFullyLoaded: boolean = false;

  constructor(private auth: AuthService, private acl: AclService, private router: Router, private firmService: FirmService) {
    this.firmService.subject.subscribe(firm => {
      if (firm) {
        this.firm = firm;
        this.isFullyLoaded = true;
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authUser = this.auth.getUser();

    if (!this.isFullyLoaded) {
      if(state.url === '/login' || state.url === '/signup') {
        return true;
      } else {
       if(!authUser?.token) {
         return this.logout()
       }
      }
    }
    const userDetail = this.auth.getUserDetail();

    if (this.allowedPagesOnRoleIdTwo.includes(state.url) && userDetail?.useraccesstypeid != 2) {
      return this.logout();
    }
    if (StorageService.applicationModel.billing) {
      if (((this.firm.isAccountLockedForBilling) && !this.allowedPagesOnBadBilling.includes(state.url)) ||
        (!(userDetail?.useracctid == this.firm?.pauseCanceledBy) && StorageService.applicationModel.billing.isPaused &&
          (state.url === '/dashboard/members/settings/billing/add-new-card'))) {
        if (StorageService.applicationModel.billing.isPaused && userDetail.isbillingenabled) {
          this.router.navigate(['/dashboard/members/settings/billing']);
          return false;
        }
        return this.logout();
      }
    }
    this.handleRoutes(state.url)
    return true;
  }

  private logout(): false {
    this.auth.logout();
    return false;
  }

  private handleRoutes(routeUrl: string) {
    if(routeUrl.includes('ntrdashboard') && !(localStorage.getItem('selectedEngagementObj'))) {
      this.router.navigate(['dashboard/engagement/engagement-list']);
      return false;
    }
  }
}
